<template>
    <div>

        <b-modal :ref="modal_name" hide-footer :title="modal_title">
			<div class="d-block text-center">
				<b-btn variant="primary" block @click="take_photo">{{ $t('photo.prendre_photo') }}</b-btn>
				<b-btn variant="outline-primary" block @click="open_file_picker">{{ $t('photo.choisir_photo') }}</b-btn>
			</div>
			<b-btn class="mt-3" variant="outline-danger" block @click="hide_modal">{{ $t('global.annuler') }}</b-btn>
		</b-modal>

    </div>
</template>

<script type="text/javascript">
    import Medias from "@/mixins/Medias.js";

	export default {
		props:[
            'modal_title',
            'modal_name',
            'callback_form',
            'callback_error'
		],
		mixins: [
            Medias
		],
		methods:{
			hide_modal() {
				this.$refs[this.modal_name].hide()
			},

            take_photo() {
                this.takePhoto(this.on_success, this.on_failure)
            },

            open_file_picker() {
                this.openFilePicker(this.on_success, this.on_failure)
            },

            on_success(imageData) {
                this.hide_modal()
                return this.callback_form(imageData)
            },

            on_failure(message) {
                return this.callback_error(message)
            }
		}
	}
</script>
